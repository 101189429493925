import axios from 'axios';
// @ts-ignore: JS.
// eslint-disable-next-line import/no-cycle -- Dependency cycle
import jwt from '@app/src/security/jwt';
import { useToast } from '@mezzoforte/forge';
import { isServiceUnavailableError, isTooManyRequestsError } from '@app/src/util/axios-errors';

// eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: Move client creation into a hook.
const { playToast } = useToast();

/** @deprecated use useApi instead */
export default (baseUrl: string = '/api') => {
  const headers = {
    retries: 0,
    'Cache-Control': 'no-cache',
  };

  const client = axios.create({
    withCredentials: true,
    baseURL: baseUrl,
    xsrfCookieName: 'csrf-token',
    xsrfHeaderName: 'X-Csrf-Token',
    headers: {
      ...axios.defaults.headers,
      ...headers,
    },
  });

  client.interceptors.response.use(
    response => response,
    async error => {
      if (axios.isCancel(error)) {
        return Promise.resolve();
      }

      if (isServiceUnavailableError(error)) {
        playToast(
          'Pieni hetki, huollamme sivustoa',
          'Huutokaupat.com on pois käytöstä huoltotöiden takia. Pyrimme saamaan palvelun käyttöön mahdollisimman pian.',
          {
            toastId: 'service-unavailable-error',
            variant: 'danger',
            closeManually: true,
          }
        );
      }

      if (isTooManyRequestsError(error)) {
        playToast('Virhe', 'Liikaa pyyntöjä. Odota hetki ja yritä sen jälkeen uudestaan.', {
          toastId: 'too-many-requests-error',
          variant: 'danger',
          closeManually: true,
        });
      }

      // Remove clients login information if backend does not accept it.
      if (error?.response?.status === 401) {
        jwt.clearToken();
      }

      return Promise.reject(error);
    }
  );

  return client;
};
